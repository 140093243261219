import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import styles from './FileUploader.module.scss';

export interface FileUploaderProps {
    name: string;
    label?: string;
}

const FileUploader: React.FC<FileUploaderProps> = ({ name, label }) => (
  <div className={styles.fileUploader}>
    <input id={name} name={name} type="file" hidden />
    <span className={styles.fileUploaderPlaceholder}>
      {label || <FormattedMessage id="components.accountSettings.contents.account_details.no_file_chosen" />}
    </span>
    <label htmlFor={name} className={styles.fileUploaderButton}>Select file</label>
  </div>
);

export default FileUploader;
