import React from 'react';
import { Helmet } from 'react-helmet';

import AccountSettingsComponent from 'components/Dashboard/AccountSettings';
import Layout from 'components/layout';

const AccountSettingsPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Account settings</title>
      <meta name="og:title" content="Account settings" />
      <meta name="twitter:title" content="Account settings" />
      <body className="dashboard" />
    </Helmet>
    <AccountSettingsComponent />
  </Layout>
);

export default AccountSettingsPage;
