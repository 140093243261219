import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input/input';
import Label, { LabelText } from 'components/UI/Label';

import styles from '../Settings.module.scss';

const AccountDetails: React.FC = () => (
  <>
    <h5 className={styles.tabsContentTitle}>
      <FormattedMessage id="components.accountSettings.contents.account_details.personal_information" />
    </h5>
    <div className="row mb-4">
      <div className="col-4">
        <Label>
          <LabelText>
            <FormattedMessage id="components.accountSettings.contents.account_details.name" />
          </LabelText>
          <Input value="Jack Black" disabled />
        </Label>
      </div>
      <div className="col-4">
        <Label>
          <LabelText>
            <FormattedMessage id="components.accountSettings.contents.account_details.username" />
          </LabelText>
          <Input value="@jackblack" disabled />
        </Label>
      </div>
    </div>
    <div className="row mb-4">
      <div className="col-8">
        <Label>
          <LabelText>
            <FormattedMessage id="components.accountSettings.contents.account_details.phone" />
          </LabelText>
          <Input value="+33 6 44 55 33 42" disabled />
        </Label>
      </div>
    </div>
    <div className="row mb-4">
      <div className="col-8">
        <Label>
          <LabelText>
            <FormattedMessage id="components.accountSettings.contents.account_details.email" />
          </LabelText>
          <Input />
        </Label>
      </div>
    </div>
    <div className="col-8 d-flex justify-content-end">
      <Button>
        <FormattedMessage id="components.accountSettings.save" />
      </Button>
    </div>
  </>
);

export default AccountDetails;
