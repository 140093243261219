import React from 'react';

import LabelError from './LabelError';
import LabelText from './LabelText';

import styles from './Lable.module.scss';

const Label: React.FC = ({ children }) => (
  <label className={styles.label}>
    {children}
  </label>
);
export { LabelText, LabelError };
export default Label;
