import { FormattedMessage } from 'gatsby-plugin-intl';
import React from 'react';

import styles from '../Settings.module.scss';

import ItemsName from './enums';

const SettingsMenuItems: React.FC = () => {
  const [activeItem, setActiveItem] = React.useState('');

  const handleScroll = (count: number, item: string): void => {
    window.scrollTo({ top: count, behavior: 'smooth' });
    setActiveItem(item);
  };

  return (
    <div className="col-3">
      <div className={styles.settingsTabs}>
        <span className={styles.settingsTabsTitle}>Account settings</span>

        <div className={styles.settingsTabsItems}>
          <button
            type="button"
            className={activeItem === ItemsName.accountDetails ? styles.active : ''}
            onClick={(): void => handleScroll(120, ItemsName.accountDetails)}
          >
            <FormattedMessage id="components.accountSettings.titles.account_details" />
          </button>
          <button
            type="button"
            onClick={(): void => handleScroll(600, ItemsName.billingDetails)}
            className={activeItem === ItemsName.billingDetails ? styles.active : ''}
          >
            <FormattedMessage id="components.accountSettings.titles.billing_details" />
          </button>
          <button
            type="button"
            onClick={(): void => handleScroll(1200, ItemsName.accountValidation)}
            className={activeItem === ItemsName.accountValidation ? styles.active : ''}
          >
            <FormattedMessage id="components.accountSettings.titles.account_validation" />
          </button>
          <button
            type="button"
            onClick={(): void => handleScroll(1650, ItemsName.languages)}
            className={activeItem === ItemsName.languages ? styles.active : ''}
          >
            <FormattedMessage id="components.accountSettings.titles.languages" />
          </button>
        </div>
      </div>
    </div>
  );
};
export default SettingsMenuItems;
