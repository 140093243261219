import React from 'react';

import styles from './LabelError.module.scss';

const LabelError: React.FC = ({ children }) => (
  <span className={styles.labelError}>
    {children}
  </span>
);

export default LabelError;
