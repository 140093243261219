import * as React from 'react';

import styles from './SelectBox.module.scss';

export interface SelectBoxBGProps {
  handleCloseSelectBox: () => void;
}

const SelectBoxBG: React.FC<SelectBoxBGProps> = ({ handleCloseSelectBox }) => (
  <div
    className={styles.fixedBlackBG}
    role="button"
    onClick={(): void => handleCloseSelectBox()}
    onKeyDown={(): void => handleCloseSelectBox()}
    tabIndex={0}
    aria-label="select background"
  />
);

export default SelectBoxBG;
