import React, { useState } from 'react';

import styles from './SelectBox.module.scss';

export interface SelectBoxBodyProps {
  isOpen: boolean;
  setIsOpen: (data: boolean) => void;
  items: SelectBoxItems;
  name: string;
  title: string;
  handleChange?: (item: SelectBoxHandleChange) => void;
  image?: boolean;
}

const SelectBoxBody: React.FC<SelectBoxBodyProps> = ({
  items, title, isOpen, setIsOpen, name, handleChange, image,
}) => {
  const [activeItem, setActiveItem] = useState(items[0]);

  const handleSelectItem = (item: SelectBoxItem): void => {
    setActiveItem(item);
    setIsOpen(false);

    if (handleChange) handleChange({ name, value: item.value });
  };

  const SelectBoxItems = items.map((item) => (
    <li
      className={activeItem.label === item.label ? styles.activeItem : ''}
      key={item.value}
    >
      <div
        role="button"
        onKeyDown={(): void => handleSelectItem(item)}
        tabIndex={0}
        onClick={(): void => handleSelectItem(item)}
        className={styles.selectBoxItemWrapp}
      >
        {image && <img className={styles.selectBoxImage} src={item.image} alt={item.label} />}
        <span>{item.label}</span>
      </div>
    </li>
  ));

  return (
    <div className={styles.selectBox}>

      <span className={styles.selectBoxTitle}>
        {title}
      </span>

      <div
        className={styles.selectedItem}
        onClick={(): void => setIsOpen(!isOpen)}
        role="button"
        onKeyDown={(): void => setIsOpen(!isOpen)}
        tabIndex={0}
      >
        {image
          && (
          <img
            className={styles.selectBoxImage}
            src={activeItem.image}
            alt={activeItem.label}
          />
          )}
        <span className={styles.selectBoxText}>{activeItem.label}</span>
      </div>

      {isOpen && (
        <ul className={styles.selectBoxItems}>
          {SelectBoxItems}
        </ul>
      )}

    </div>
  );
};

export default SelectBoxBody;
