import React from 'react';

import styles from './LabelText.module.scss';

const LabelText: React.FC = ({ children }) => (
  <span className={styles.labelText}>
    {children}
  </span>
);

export default LabelText;
