import React from 'react';

import Settings from './Settings/index';

const AccountSettingsComponent: React.FC = () => (
  <Settings>
    <Settings.MenuItems />
    <Settings.Sections />
  </Settings>
);

export default AccountSettingsComponent;
