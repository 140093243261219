import classNames from 'classnames';
import React from 'react';

import styles from '../Settings.module.scss';

import AccountDetails from './AccountDetails';
import AccountValidation from './AccountValidation';
import BillingDetails from './BillingDetails';

const SettingsSections: React.FC = () => (
  <div id="account_details" className={classNames(styles.settingsTabsContent, 'col-9')}>
    <AccountDetails />
    <BillingDetails />
    <AccountValidation />
  </div>
);

export default SettingsSections;
