const countriesFlag = [
  {
    image: 'https://restcountries.eu/data/afg.svg',
    value: 'Afghanistan',
    label: 'Afghanistan',
  },
  {
    image: 'https://restcountries.eu/data/ala.svg',
    value: 'Åland Islands',
    label: 'Åland Islands',
  },
  {
    image: 'https://restcountries.eu/data/alb.svg',
    value: 'Albania',
    label: 'Albania',
  },
  {
    image: 'https://restcountries.eu/data/dza.svg',
    value: 'Algeria',
    label: 'Algeria',
  },
  {
    image: 'https://restcountries.eu/data/asm.svg',
    value: 'American Samoa',
    label: 'American Samoa',
  },
  {
    image: 'https://restcountries.eu/data/and.svg',
    value: 'Andorra',
    label: 'Andorra',
  },
  {
    image: 'https://restcountries.eu/data/ago.svg',
    value: 'Angola',
    label: 'Angola',
  },
  {
    image: 'https://restcountries.eu/data/aia.svg',
    value: 'Anguilla',
    label: 'Anguilla',
  },
  {
    image: 'https://restcountries.eu/data/ata.svg',
    value: 'Antarctica',
    label: 'Antarctica',
  },
  {
    image: 'https://restcountries.eu/data/atg.svg',
    value: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
  },
  {
    image: 'https://restcountries.eu/data/arg.svg',
    value: 'Argentina',
    label: 'Argentina',
  },
  {
    image: 'https://restcountries.eu/data/arm.svg',
    value: 'Armenia',
    label: 'Armenia',
  },
  {
    image: 'https://restcountries.eu/data/abw.svg',
    value: 'Aruba',
    label: 'Aruba',
  },
  {
    image: 'https://restcountries.eu/data/aus.svg',
    value: 'Australia',
    label: 'Australia',
  },
  {
    image: 'https://restcountries.eu/data/aut.svg',
    value: 'Austria',
    label: 'Austria',
  },
  {
    image: 'https://restcountries.eu/data/aze.svg',
    value: 'Azerbaijan',
    label: 'Azerbaijan',
  },
  {
    image: 'https://restcountries.eu/data/bhs.svg',
    value: 'Bahamas',
    label: 'Bahamas',
  },
  {
    image: 'https://restcountries.eu/data/bhr.svg',
    value: 'Bahrain',
    label: 'Bahrain',
  },
  {
    image: 'https://restcountries.eu/data/bgd.svg',
    value: 'Bangladesh',
    label: 'Bangladesh',
  },
  {
    image: 'https://restcountries.eu/data/brb.svg',
    value: 'Barbados',
    label: 'Barbados',
  },
  {
    image: 'https://restcountries.eu/data/blr.svg',
    value: 'Belarus',
    label: 'Belarus',
  },
  {
    image: 'https://restcountries.eu/data/bel.svg',
    value: 'Belgium',
    label: 'Belgium',
  },
  {
    image: 'https://restcountries.eu/data/blz.svg',
    value: 'Belize',
    label: 'Belize',
  },
  {
    image: 'https://restcountries.eu/data/ben.svg',
    value: 'Benin',
    label: 'Benin',
  },
  {
    image: 'https://restcountries.eu/data/bmu.svg',
    value: 'Bermuda',
    label: 'Bermuda',
  },
  {
    image: 'https://restcountries.eu/data/btn.svg',
    value: 'Bhutan',
    label: 'Bhutan',
  },
  {
    image: 'https://restcountries.eu/data/bol.svg',
    value: 'Bolivia (Plurinational State of)',
    label: 'Bolivia (Plurinational State of)',
  },
  {
    image: 'https://restcountries.eu/data/bes.svg',
    value: 'Bonaire, Sint Eustatius and Saba',
    label: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    image: 'https://restcountries.eu/data/bih.svg',
    value: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
  },
  {
    image: 'https://restcountries.eu/data/bwa.svg',
    value: 'Botswana',
    label: 'Botswana',
  },
  {
    image: 'https://restcountries.eu/data/bvt.svg',
    value: 'Bouvet Island',
    label: 'Bouvet Island',
  },
  {
    image: 'https://restcountries.eu/data/bra.svg',
    value: 'Brazil',
    label: 'Brazil',
  },
  {
    image: 'https://restcountries.eu/data/iot.svg',
    value: 'British Indian Ocean Territory',
    label: 'British Indian Ocean Territory',
  },
  {
    image: 'https://restcountries.eu/data/umi.svg',
    value: 'United States Minor Outlying Islands',
    label: 'United States Minor Outlying Islands',
  },
  {
    image: 'https://restcountries.eu/data/vgb.svg',
    value: 'Virgin Islands (British)',
    label: 'Virgin Islands (British)',
  },
  {
    image: 'https://restcountries.eu/data/vir.svg',
    value: 'Virgin Islands (U.S.)',
    label: 'Virgin Islands (U.S.)',
  },
  {
    image: 'https://restcountries.eu/data/brn.svg',
    value: 'Brunei Darussalam',
    label: 'Brunei Darussalam',
  },
  {
    image: 'https://restcountries.eu/data/bgr.svg',
    value: 'Bulgaria',
    label: 'Bulgaria',
  },
  {
    image: 'https://restcountries.eu/data/bfa.svg',
    value: 'Burkina Faso',
    label: 'Burkina Faso',
  },
  {
    image: 'https://restcountries.eu/data/bdi.svg',
    value: 'Burundi',
    label: 'Burundi',
  },
  {
    image: 'https://restcountries.eu/data/khm.svg',
    value: 'Cambodia',
    label: 'Cambodia',
  },
  {
    image: 'https://restcountries.eu/data/cmr.svg',
    value: 'Cameroon',
    label: 'Cameroon',
  },
  {
    image: 'https://restcountries.eu/data/can.svg',
    value: 'Canada',
    label: 'Canada',
  },
  {
    image: 'https://restcountries.eu/data/cpv.svg',
    value: 'Cabo Verde',
    label: 'Cabo Verde',
  },
  {
    image: 'https://restcountries.eu/data/cym.svg',
    value: 'Cayman Islands',
    label: 'Cayman Islands',
  },
  {
    image: 'https://restcountries.eu/data/caf.svg',
    value: 'Central African Republic',
    label: 'Central African Republic',
  },
  {
    image: 'https://restcountries.eu/data/tcd.svg',
    value: 'Chad',
    label: 'Chad',
  },
  {
    image: 'https://restcountries.eu/data/chl.svg',
    value: 'Chile',
    label: 'Chile',
  },
  {
    image: 'https://restcountries.eu/data/chn.svg',
    value: 'China',
    label: 'China',
  },
  {
    image: 'https://restcountries.eu/data/cxr.svg',
    value: 'Christmas Island',
    label: 'Christmas Island',
  },
  {
    image: 'https://restcountries.eu/data/cck.svg',
    value: 'Cocos (Keeling) Islands',
    label: 'Cocos (Keeling) Islands',
  },
  {
    image: 'https://restcountries.eu/data/col.svg',
    value: 'Colombia',
    label: 'Colombia',
  },
  {
    image: 'https://restcountries.eu/data/com.svg',
    value: 'Comoros',
    label: 'Comoros',
  },
  {
    image: 'https://restcountries.eu/data/cog.svg',
    value: 'Congo',
    label: 'Congo',
  },
  {
    image: 'https://restcountries.eu/data/cod.svg',
    value: 'Congo (Democratic Republic of the)',
    label: 'Congo (Democratic Republic of the)',
  },
  {
    image: 'https://restcountries.eu/data/cok.svg',
    value: 'Cook Islands',
    label: 'Cook Islands',
  },
  {
    image: 'https://restcountries.eu/data/cri.svg',
    value: 'Costa Rica',
    label: 'Costa Rica',
  },
  {
    image: 'https://restcountries.eu/data/hrv.svg',
    value: 'Croatia',
    label: 'Croatia',
  },
  {
    image: 'https://restcountries.eu/data/cub.svg',
    value: 'Cuba',
    label: 'Cuba',
  },
  {
    image: 'https://restcountries.eu/data/cuw.svg',
    value: 'Curaçao',
    label: 'Curaçao',
  },
  {
    image: 'https://restcountries.eu/data/cyp.svg',
    value: 'Cyprus',
    label: 'Cyprus',
  },
  {
    image: 'https://restcountries.eu/data/cze.svg',
    value: 'Czech Republic',
    label: 'Czech Republic',
  },
  {
    image: 'https://restcountries.eu/data/dnk.svg',
    value: 'Denmark',
    label: 'Denmark',
  },
  {
    image: 'https://restcountries.eu/data/dji.svg',
    value: 'Djibouti',
    label: 'Djibouti',
  },
  {
    image: 'https://restcountries.eu/data/dma.svg',
    value: 'Dominica',
    label: 'Dominica',
  },
  {
    image: 'https://restcountries.eu/data/dom.svg',
    value: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  {
    image: 'https://restcountries.eu/data/ecu.svg',
    value: 'Ecuador',
    label: 'Ecuador',
  },
  {
    image: 'https://restcountries.eu/data/egy.svg',
    value: 'Egypt',
    label: 'Egypt',
  },
  {
    image: 'https://restcountries.eu/data/slv.svg',
    value: 'El Salvador',
    label: 'El Salvador',
  },
  {
    image: 'https://restcountries.eu/data/gnq.svg',
    value: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
  },
  {
    image: 'https://restcountries.eu/data/eri.svg',
    value: 'Eritrea',
    label: 'Eritrea',
  },
  {
    image: 'https://restcountries.eu/data/est.svg',
    value: 'Estonia',
    label: 'Estonia',
  },
  {
    image: 'https://restcountries.eu/data/eth.svg',
    value: 'Ethiopia',
    label: 'Ethiopia',
  },
  {
    image: 'https://restcountries.eu/data/flk.svg',
    value: 'Falkland Islands (Malvinas)',
    label: 'Falkland Islands (Malvinas)',
  },
  {
    image: 'https://restcountries.eu/data/fro.svg',
    value: 'Faroe Islands',
    label: 'Faroe Islands',
  },
  {
    image: 'https://restcountries.eu/data/fji.svg',
    value: 'Fiji',
    label: 'Fiji',
  },
  {
    image: 'https://restcountries.eu/data/fin.svg',
    value: 'Finland',
    label: 'Finland',
  },
  {
    image: 'https://restcountries.eu/data/fra.svg',
    value: 'France',
    label: 'France',
  },
  {
    image: 'https://restcountries.eu/data/guf.svg',
    value: 'French Guiana',
    label: 'French Guiana',
  },
  {
    image: 'https://restcountries.eu/data/pyf.svg',
    value: 'French Polynesia',
    label: 'French Polynesia',
  },
  {
    image: 'https://restcountries.eu/data/atf.svg',
    value: 'French Southern Territories',
    label: 'French Southern Territories',
  },
  {
    image: 'https://restcountries.eu/data/gab.svg',
    value: 'Gabon',
    label: 'Gabon',
  },
  {
    image: 'https://restcountries.eu/data/gmb.svg',
    value: 'Gambia',
    label: 'Gambia',
  },
  {
    image: 'https://restcountries.eu/data/geo.svg',
    value: 'Georgia',
    label: 'Georgia',
  },
  {
    image: 'https://restcountries.eu/data/deu.svg',
    value: 'Germany',
    label: 'Germany',
  },
  {
    image: 'https://restcountries.eu/data/gha.svg',
    value: 'Ghana',
    label: 'Ghana',
  },
  {
    image: 'https://restcountries.eu/data/gib.svg',
    value: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    image: 'https://restcountries.eu/data/grc.svg',
    value: 'Greece',
    label: 'Greece',
  },
  {
    image: 'https://restcountries.eu/data/grl.svg',
    value: 'Greenland',
    label: 'Greenland',
  },
  {
    image: 'https://restcountries.eu/data/grd.svg',
    value: 'Grenada',
    label: 'Grenada',
  },
  {
    image: 'https://restcountries.eu/data/glp.svg',
    value: 'Guadeloupe',
    label: 'Guadeloupe',
  },
  {
    image: 'https://restcountries.eu/data/gum.svg',
    value: 'Guam',
    label: 'Guam',
  },
  {
    image: 'https://restcountries.eu/data/gtm.svg',
    value: 'Guatemala',
    label: 'Guatemala',
  },
  {
    image: 'https://restcountries.eu/data/ggy.svg',
    value: 'Guernsey',
    label: 'Guernsey',
  },
  {
    image: 'https://restcountries.eu/data/gin.svg',
    value: 'Guinea',
    label: 'Guinea',
  },
  {
    image: 'https://restcountries.eu/data/gnb.svg',
    value: 'Guinea-Bissau',
    label: 'Guinea-Bissau',
  },
  {
    image: 'https://restcountries.eu/data/guy.svg',
    value: 'Guyana',
    label: 'Guyana',
  },
  {
    image: 'https://restcountries.eu/data/hti.svg',
    value: 'Haiti',
    label: 'Haiti',
  },
  {
    image: 'https://restcountries.eu/data/hmd.svg',
    value: 'Heard Island and McDonald Islands',
    label: 'Heard Island and McDonald Islands',
  },
  {
    image: 'https://restcountries.eu/data/vat.svg',
    value: 'Holy See',
    label: 'Holy See',
  },
  {
    image: 'https://restcountries.eu/data/hnd.svg',
    value: 'Honduras',
    label: 'Honduras',
  },
  {
    image: 'https://restcountries.eu/data/hkg.svg',
    value: 'Hong Kong',
    label: 'Hong Kong',
  },
  {
    image: 'https://restcountries.eu/data/hun.svg',
    value: 'Hungary',
    label: 'Hungary',
  },
  {
    image: 'https://restcountries.eu/data/isl.svg',
    value: 'Iceland',
    label: 'Iceland',
  },
  {
    image: 'https://restcountries.eu/data/ind.svg',
    value: 'India',
    label: 'India',
  },
  {
    image: 'https://restcountries.eu/data/idn.svg',
    value: 'Indonesia',
    label: 'Indonesia',
  },
  {
    image: 'https://restcountries.eu/data/civ.svg',
    value: "Côte d'Ivoire",
    label: "Côte d'Ivoire",
  },
  {
    image: 'https://restcountries.eu/data/irn.svg',
    value: 'Iran (Islamic Republic of)',
    label: 'Iran (Islamic Republic of)',
  },
  {
    image: 'https://restcountries.eu/data/irq.svg',
    value: 'Iraq',
    label: 'Iraq',
  },
  {
    image: 'https://restcountries.eu/data/irl.svg',
    value: 'Ireland',
    label: 'Ireland',
  },
  {
    image: 'https://restcountries.eu/data/imn.svg',
    value: 'Isle of Man',
    label: 'Isle of Man',
  },
  {
    image: 'https://restcountries.eu/data/isr.svg',
    value: 'Israel',
    label: 'Israel',
  },
  {
    image: 'https://restcountries.eu/data/ita.svg',
    value: 'Italy',
    label: 'Italy',
  },
  {
    image: 'https://restcountries.eu/data/jam.svg',
    value: 'Jamaica',
    label: 'Jamaica',
  },
  {
    image: 'https://restcountries.eu/data/jpn.svg',
    value: 'Japan',
    label: 'Japan',
  },
  {
    image: 'https://restcountries.eu/data/jey.svg',
    value: 'Jersey',
    label: 'Jersey',
  },
  {
    image: 'https://restcountries.eu/data/jor.svg',
    value: 'Jordan',
    label: 'Jordan',
  },
  {
    image: 'https://restcountries.eu/data/kaz.svg',
    value: 'Kazakhstan',
    label: 'Kazakhstan',
  },
  {
    image: 'https://restcountries.eu/data/ken.svg',
    value: 'Kenya',
    label: 'Kenya',
  },
  {
    image: 'https://restcountries.eu/data/kir.svg',
    value: 'Kiribati',
    label: 'Kiribati',
  },
  {
    image: 'https://restcountries.eu/data/kwt.svg',
    value: 'Kuwait',
    label: 'Kuwait',
  },
  {
    image: 'https://restcountries.eu/data/kgz.svg',
    value: 'Kyrgyzstan',
    label: 'Kyrgyzstan',
  },
  {
    image: 'https://restcountries.eu/data/lao.svg',
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  {
    image: 'https://restcountries.eu/data/lva.svg',
    value: 'Latvia',
    label: 'Latvia',
  },
  {
    image: 'https://restcountries.eu/data/lbn.svg',
    value: 'Lebanon',
    label: 'Lebanon',
  },
  {
    image: 'https://restcountries.eu/data/lso.svg',
    value: 'Lesotho',
    label: 'Lesotho',
  },
  {
    image: 'https://restcountries.eu/data/lbr.svg',
    value: 'Liberia',
    label: 'Liberia',
  },
  {
    image: 'https://restcountries.eu/data/lby.svg',
    value: 'Libya',
    label: 'Libya',
  },
  {
    image: 'https://restcountries.eu/data/lie.svg',
    value: 'Liechtenstein',
    label: 'Liechtenstein',
  },
  {
    image: 'https://restcountries.eu/data/ltu.svg',
    value: 'Lithuania',
    label: 'Lithuania',
  },
  {
    image: 'https://restcountries.eu/data/lux.svg',
    value: 'Luxembourg',
    label: 'Luxembourg',
  },
  {
    image: 'https://restcountries.eu/data/mac.svg',
    value: 'Macao',
    label: 'Macao',
  },
  {
    image: 'https://restcountries.eu/data/mkd.svg',
    value: 'Macedonia (the former Yugoslav Republic of)',
    label: 'Macedonia (the former Yugoslav Republic of)',
  },
  {
    image: 'https://restcountries.eu/data/mdg.svg',
    value: 'Madagascar',
    label: 'Madagascar',
  },
  {
    image: 'https://restcountries.eu/data/mwi.svg',
    value: 'Malawi',
    label: 'Malawi',
  },
  {
    image: 'https://restcountries.eu/data/mys.svg',
    value: 'Malaysia',
    label: 'Malaysia',
  },
  {
    image: 'https://restcountries.eu/data/mdv.svg',
    value: 'Maldives',
    label: 'Maldives',
  },
  {
    image: 'https://restcountries.eu/data/mli.svg',
    value: 'Mali',
    label: 'Mali',
  },
  {
    image: 'https://restcountries.eu/data/mlt.svg',
    value: 'Malta',
    label: 'Malta',
  },
  {
    image: 'https://restcountries.eu/data/mhl.svg',
    value: 'Marshall Islands',
    label: 'Marshall Islands',
  },
  {
    image: 'https://restcountries.eu/data/mtq.svg',
    value: 'Martinique',
    label: 'Martinique',
  },
  {
    image: 'https://restcountries.eu/data/mrt.svg',
    value: 'Mauritania',
    label: 'Mauritania',
  },
  {
    image: 'https://restcountries.eu/data/mus.svg',
    value: 'Mauritius',
    label: 'Mauritius',
  },
  {
    image: 'https://restcountries.eu/data/myt.svg',
    value: 'Mayotte',
    label: 'Mayotte',
  },
  {
    image: 'https://restcountries.eu/data/mex.svg',
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    image: 'https://restcountries.eu/data/fsm.svg',
    value: 'Micronesia (Federated States of)',
    label: 'Micronesia (Federated States of)',
  },
  {
    image: 'https://restcountries.eu/data/mda.svg',
    value: 'Moldova (Republic of)',
    label: 'Moldova (Republic of)',
  },
  {
    image: 'https://restcountries.eu/data/mco.svg',
    value: 'Monaco',
    label: 'Monaco',
  },
  {
    image: 'https://restcountries.eu/data/mng.svg',
    value: 'Mongolia',
    label: 'Mongolia',
  },
  {
    image: 'https://restcountries.eu/data/mne.svg',
    value: 'Montenegro',
    label: 'Montenegro',
  },
  {
    image: 'https://restcountries.eu/data/msr.svg',
    value: 'Montserrat',
    label: 'Montserrat',
  },
  {
    image: 'https://restcountries.eu/data/mar.svg',
    value: 'Morocco',
    label: 'Morocco',
  },
  {
    image: 'https://restcountries.eu/data/moz.svg',
    value: 'Mozambique',
    label: 'Mozambique',
  },
  {
    image: 'https://restcountries.eu/data/mmr.svg',
    value: 'Myanmar',
    label: 'Myanmar',
  },
  {
    image: 'https://restcountries.eu/data/nam.svg',
    value: 'Namibia',
    label: 'Namibia',
  },
  {
    image: 'https://restcountries.eu/data/nru.svg',
    value: 'Nauru',
    label: 'Nauru',
  },
  {
    image: 'https://restcountries.eu/data/npl.svg',
    value: 'Nepal',
    label: 'Nepal',
  },
  {
    image: 'https://restcountries.eu/data/nld.svg',
    value: 'Netherlands',
    label: 'Netherlands',
  },
  {
    image: 'https://restcountries.eu/data/ncl.svg',
    value: 'New Caledonia',
    label: 'New Caledonia',
  },
  {
    image: 'https://restcountries.eu/data/nzl.svg',
    value: 'New Zealand',
    label: 'New Zealand',
  },
  {
    image: 'https://restcountries.eu/data/nic.svg',
    value: 'Nicaragua',
    label: 'Nicaragua',
  },
  {
    image: 'https://restcountries.eu/data/ner.svg',
    value: 'Niger',
    label: 'Niger',
  },
  {
    image: 'https://restcountries.eu/data/nga.svg',
    value: 'Nigeria',
    label: 'Nigeria',
  },
  {
    image: 'https://restcountries.eu/data/niu.svg',
    value: 'Niue',
    label: 'Niue',
  },
  {
    image: 'https://restcountries.eu/data/nfk.svg',
    value: 'Norfolk Island',
    label: 'Norfolk Island',
  },
  {
    image: 'https://restcountries.eu/data/prk.svg',
    value: "Korea (Democratic People's Republic of)",
    label: "Korea (Democratic People's Republic of)",
  },
  {
    image: 'https://restcountries.eu/data/mnp.svg',
    value: 'Northern Mariana Islands',
    label: 'Northern Mariana Islands',
  },
  {
    image: 'https://restcountries.eu/data/nor.svg',
    value: 'Norway',
    label: 'Norway',
  },
  {
    image: 'https://restcountries.eu/data/omn.svg',
    value: 'Oman',
    label: 'Oman',
  },
  {
    image: 'https://restcountries.eu/data/pak.svg',
    value: 'Pakistan',
    label: 'Pakistan',
  },
  {
    image: 'https://restcountries.eu/data/plw.svg',
    value: 'Palau',
    label: 'Palau',
  },
  {
    image: 'https://restcountries.eu/data/pse.svg',
    value: 'Palestine, State of',
    label: 'Palestine, State of',
  },
  {
    image: 'https://restcountries.eu/data/pan.svg',
    value: 'Panama',
    label: 'Panama',
  },
  {
    image: 'https://restcountries.eu/data/png.svg',
    value: 'Papua New Guinea',
    label: 'Papua New Guinea',
  },
  {
    image: 'https://restcountries.eu/data/pry.svg',
    value: 'Paraguay',
    label: 'Paraguay',
  },
  {
    image: 'https://restcountries.eu/data/per.svg',
    value: 'Peru',
    label: 'Peru',
  },
  {
    image: 'https://restcountries.eu/data/phl.svg',
    value: 'Philippines',
    label: 'Philippines',
  },
  {
    image: 'https://restcountries.eu/data/pcn.svg',
    value: 'Pitcairn',
    label: 'Pitcairn',
  },
  {
    image: 'https://restcountries.eu/data/pol.svg',
    value: 'Poland',
    label: 'Poland',
  },
  {
    image: 'https://restcountries.eu/data/prt.svg',
    value: 'Portugal',
    label: 'Portugal',
  },
  {
    image: 'https://restcountries.eu/data/pri.svg',
    value: 'Puerto Rico',
    label: 'Puerto Rico',
  },
  {
    image: 'https://restcountries.eu/data/qat.svg',
    value: 'Qatar',
    label: 'Qatar',
  },
  {
    image: 'https://restcountries.eu/data/kos.svg',
    value: 'Republic of Kosovo',
    label: 'Republic of Kosovo',
  },
  {
    image: 'https://restcountries.eu/data/reu.svg',
    value: 'Réunion',
    label: 'Réunion',
  },
  {
    image: 'https://restcountries.eu/data/rou.svg',
    value: 'Romania',
    label: 'Romania',
  },
  {
    image: 'https://restcountries.eu/data/rus.svg',
    value: 'Russian Federation',
    label: 'Russian Federation',
  },
  {
    image: 'https://restcountries.eu/data/rwa.svg',
    value: 'Rwanda',
    label: 'Rwanda',
  },
  {
    image: 'https://restcountries.eu/data/blm.svg',
    value: 'Saint Barthélemy',
    label: 'Saint Barthélemy',
  },
  {
    image: 'https://restcountries.eu/data/shn.svg',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
    label: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    image: 'https://restcountries.eu/data/kna.svg',
    value: 'Saint Kitts and Nevis',
    label: 'Saint Kitts and Nevis',
  },
  {
    image: 'https://restcountries.eu/data/lca.svg',
    value: 'Saint Lucia',
    label: 'Saint Lucia',
  },
  {
    image: 'https://restcountries.eu/data/maf.svg',
    value: 'Saint Martin (French part)',
    label: 'Saint Martin (French part)',
  },
  {
    image: 'https://restcountries.eu/data/spm.svg',
    value: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
  },
  {
    image: 'https://restcountries.eu/data/vct.svg',
    value: 'Saint Vincent and the Grenadines',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    image: 'https://restcountries.eu/data/wsm.svg',
    value: 'Samoa',
    label: 'Samoa',
  },
  {
    image: 'https://restcountries.eu/data/smr.svg',
    value: 'San Marino',
    label: 'San Marino',
  },
  {
    image: 'https://restcountries.eu/data/stp.svg',
    value: 'Sao Tome and Principe',
    label: 'Sao Tome and Principe',
  },
  {
    image: 'https://restcountries.eu/data/sau.svg',
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
  },
  {
    image: 'https://restcountries.eu/data/sen.svg',
    value: 'Senegal',
    label: 'Senegal',
  },
  {
    image: 'https://restcountries.eu/data/srb.svg',
    value: 'Serbia',
    label: 'Serbia',
  },
  {
    image: 'https://restcountries.eu/data/syc.svg',
    value: 'Seychelles',
    label: 'Seychelles',
  },
  {
    image: 'https://restcountries.eu/data/sle.svg',
    value: 'Sierra Leone',
    label: 'Sierra Leone',
  },
  {
    image: 'https://restcountries.eu/data/sgp.svg',
    value: 'Singapore',
    label: 'Singapore',
  },
  {
    image: 'https://restcountries.eu/data/sxm.svg',
    value: 'Sint Maarten (Dutch part)',
    label: 'Sint Maarten (Dutch part)',
  },
  {
    image: 'https://restcountries.eu/data/svk.svg',
    value: 'Slovakia',
    label: 'Slovakia',
  },
  {
    image: 'https://restcountries.eu/data/svn.svg',
    value: 'Slovenia',
    label: 'Slovenia',
  },
  {
    image: 'https://restcountries.eu/data/slb.svg',
    value: 'Solomon Islands',
    label: 'Solomon Islands',
  },
  {
    image: 'https://restcountries.eu/data/som.svg',
    value: 'Somalia',
    label: 'Somalia',
  },
  {
    image: 'https://restcountries.eu/data/zaf.svg',
    value: 'South Africa',
    label: 'South Africa',
  },
  {
    image: 'https://restcountries.eu/data/sgs.svg',
    value: 'South Georgia and the South Sandwich Islands',
    label: 'South Georgia and the South Sandwich Islands',
  },
  {
    image: 'https://restcountries.eu/data/kor.svg',
    value: 'Korea (Republic of)',
    label: 'Korea (Republic of)',
  },
  {
    image: 'https://restcountries.eu/data/ssd.svg',
    value: 'South Sudan',
    label: 'South Sudan',
  },
  {
    image: 'https://restcountries.eu/data/esp.svg',
    value: 'Spain',
    label: 'Spain',
  },
  {
    image: 'https://restcountries.eu/data/lka.svg',
    value: 'Sri Lanka',
    label: 'Sri Lanka',
  },
  {
    image: 'https://restcountries.eu/data/sdn.svg',
    value: 'Sudan',
    label: 'Sudan',
  },
  {
    image: 'https://restcountries.eu/data/sur.svg',
    value: 'Surivalue',
    label: 'Surivalue',
  },
  {
    image: 'https://restcountries.eu/data/sjm.svg',
    value: 'Svalbard and Jan Mayen',
    label: 'Svalbard and Jan Mayen',
  },
  {
    image: 'https://restcountries.eu/data/swz.svg',
    value: 'Swaziland',
    label: 'Swaziland',
  },
  {
    image: 'https://restcountries.eu/data/swe.svg',
    value: 'Sweden',
    label: 'Sweden',
  },
  {
    image: 'https://restcountries.eu/data/che.svg',
    value: 'Switzerland',
    label: 'Switzerland',
  },
  {
    image: 'https://restcountries.eu/data/syr.svg',
    value: 'Syrian Arab Republic',
    label: 'Syrian Arab Republic',
  },
  {
    image: 'https://restcountries.eu/data/twn.svg',
    value: 'Taiwan',
    label: 'Taiwan',
  },
  {
    image: 'https://restcountries.eu/data/tjk.svg',
    value: 'Tajikistan',
    label: 'Tajikistan',
  },
  {
    image: 'https://restcountries.eu/data/tza.svg',
    value: 'Tanzania, United Republic of',
    label: 'Tanzania, United Republic of',
  },
  {
    image: 'https://restcountries.eu/data/tha.svg',
    value: 'Thailand',
    label: 'Thailand',
  },
  {
    image: 'https://restcountries.eu/data/tls.svg',
    value: 'Timor-Leste',
    label: 'Timor-Leste',
  },
  {
    image: 'https://restcountries.eu/data/tgo.svg',
    value: 'Togo',
    label: 'Togo',
  },
  {
    image: 'https://restcountries.eu/data/tkl.svg',
    value: 'Tokelau',
    label: 'Tokelau',
  },
  {
    image: 'https://restcountries.eu/data/ton.svg',
    value: 'Tonga',
    label: 'Tonga',
  },
  {
    image: 'https://restcountries.eu/data/tto.svg',
    value: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
  },
  {
    image: 'https://restcountries.eu/data/tun.svg',
    value: 'Tunisia',
    label: 'Tunisia',
  },
  {
    image: 'https://restcountries.eu/data/tur.svg',
    value: 'Turkey',
    label: 'Turkey',
  },
  {
    image: 'https://restcountries.eu/data/tkm.svg',
    value: 'Turkmenistan',
    label: 'Turkmenistan',
  },
  {
    image: 'https://restcountries.eu/data/tca.svg',
    value: 'Turks and Caicos Islands',
    label: 'Turks and Caicos Islands',
  },
  {
    image: 'https://restcountries.eu/data/tuv.svg',
    value: 'Tuvalu',
    label: 'Tuvalu',
  },
  {
    image: 'https://restcountries.eu/data/uga.svg',
    value: 'Uganda',
    label: 'Uganda',
  },
  {
    image: 'https://restcountries.eu/data/ukr.svg',
    value: 'Ukraine',
    label: 'Ukraine',
  },
  {
    image: 'https://restcountries.eu/data/are.svg',
    value: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    image: 'https://restcountries.eu/data/gbr.svg',
    value: 'United Kingdom of Great Britain and Northern Ireland',
    label: 'United Kingdom of Great Britain and Northern Ireland',
  },
  {
    image: 'https://restcountries.eu/data/usa.svg',
    value: 'United States of America',
    label: 'United States of America',
  },
  {
    image: 'https://restcountries.eu/data/ury.svg',
    value: 'Uruguay',
    label: 'Uruguay',
  },
  {
    image: 'https://restcountries.eu/data/uzb.svg',
    value: 'Uzbekistan',
    label: 'Uzbekistan',
  },
  {
    image: 'https://restcountries.eu/data/vut.svg',
    value: 'Vanuatu',
    label: 'Vanuatu',
  },
  {
    image: 'https://restcountries.eu/data/ven.svg',
    value: 'Venezuela (Bolivarian Republic of)',
    label: 'Venezuela (Bolivarian Republic of)',
  },
  {
    image: 'https://restcountries.eu/data/vnm.svg',
    value: 'Viet Nam',
    label: 'Viet Nam',
  },
  {
    image: 'https://restcountries.eu/data/wlf.svg',
    value: 'Wallis and Futuna',
    label: 'Wallis and Futuna',
  },
  {
    image: 'https://restcountries.eu/data/esh.svg',
    value: 'Western Sahara',
    label: 'Western Sahara',
  },
  {
    image: 'https://restcountries.eu/data/yem.svg',
    value: 'Yemen',
    label: 'Yemen',
  },
  {
    image: 'https://restcountries.eu/data/zmb.svg',
    value: 'Zambia',
    label: 'Zambia',
  },
  {
    image: 'https://restcountries.eu/data/zwe.svg',
    value: 'Zimbabwe',
    label: 'Zimbabwe',
  },
];

export default countriesFlag;
