import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import FileUploader from 'components/UI/FileUploader';
import SelectBox from 'components/UI/SelectBox';

import styles from '../Settings.module.scss';

const AccountValidation: React.FC = () => {
  const dataMock = [
    { label: 'test', value: 'test', image: 'https://i.pravatar.cc/200?img=9' },
    { label: 'test2', value: 'test2', image: 'https://i.pravatar.cc/200?img=9' },
  ];

  return (
    <div className="mb-5 pt-5" id="account_validation">
      <h5 className={styles.tabsContentTitle}>
        <FormattedMessage id="components.accountSettings.contents.account_details.account_validation" />
      </h5>

      <h6 className={styles.tabsContentTitle}>
        <FormattedMessage id="components.accountSettings.contents.account_details.why_validate_your_account.title" />
      </h6>

      <p className={styles.tabsContentDescription}>
        <FormattedMessage id="components.accountSettings.contents.account_details.why_validate_your_account.description" />
      </p>

      <div className="mb-3">
        <h6 className={styles.tabsContentTitle}>
          <FormattedMessage id="components.accountSettings.contents.account_details.registration_doc.title" />
        </h6>
        <p className={styles.tabsContentDescription}>
          <FormattedMessage id="components.accountSettings.contents.account_details.registration_doc.description" />
        </p>
        <FileUploader name="test1" label="Add company registration document" />
      </div>

      <div className="mb-3">
        <h6 className={styles.tabsContentTitle}>
          <FormattedMessage id="components.accountSettings.contents.account_details.representative_id.title" />
        </h6>
        <p className={styles.tabsContentDescription}>
          <FormattedMessage id="components.accountSettings.contents.account_details.representative_id.description" />
        </p>
        <FileUploader name="test1" label="Add passport or ID card" />
      </div>

      <div className="row">
        <div className="col-8">
          <SelectBox
            name="bankCountry"
            items={dataMock}
            title="Languages"
            form
            image
          />
        </div>
      </div>

    </div>
  );
};

export default AccountValidation;
