import { FormattedMessage } from 'gatsby-plugin-intl';
import * as React from 'react';

import Button from 'components/UI/Button';
import Input from 'components/UI/Input/input';
import Label, { LabelText } from 'components/UI/Label';
import SelectBox from 'components/UI/SelectBox';

import styles from '../Settings.module.scss';

import countriesFlag from './dataMock';

const BillingDetails: React.FC = () => {
  const dataMock = [
    { label: 'test', value: 'test', image: 'https://i.pravatar.cc/200?img=9' },
    { label: 'test2', value: 'test2', image: 'https://i.pravatar.cc/200?img=9' },
  ];

  return (
    <div className="pt-5" id="billing_details">
      <h5 className={styles.tabsContentTitle}>
        <FormattedMessage id="components.accountSettings.contents.account_details.billing_details" />
      </h5>
      <div className="row mb-4">
        <div className="col-8">
          <Label>
            <LabelText>
              <FormattedMessage id="components.accountSettings.contents.account_details.company_name" />
            </LabelText>
            <Input />
          </Label>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-8">
          <Label>
            <LabelText>
              <FormattedMessage id="components.accountSettings.contents.account_details.iban" />
            </LabelText>
            <Input placeholder="FR## #### #### #### ####" />
          </Label>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-4">
          <SelectBox
            name="bankCountry"
            items={countriesFlag}
            title="Bank country"
            form
            image
          />
        </div>
        <div className="col-4">
          <SelectBox
            name="currency"
            items={dataMock}
            title="Currency"
            form
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-8">
          <Label>
            <LabelText>
              <FormattedMessage id="components.accountSettings.contents.account_details.vat" />
            </LabelText>
            <Input />
          </Label>
        </div>
      </div>
      <div className="col-8 d-flex justify-content-end">
        <Button disabled>
          <FormattedMessage id="components.accountSettings.save" />
        </Button>
      </div>
    </div>
  );
};

export default BillingDetails;
