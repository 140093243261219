import classNames from 'classnames';
import * as React from 'react';

import styles from './SelectBox.module.scss';

export interface SelectBoxContainerProps {
    isOpen: boolean;
    form?: boolean;
}

const SelectBoxContainer: React.FC<SelectBoxContainerProps> = ({ isOpen, children, form }) => (
  <div
    className={
      classNames(
        styles.selectBoxContainer, { [styles.active]: isOpen }, form && styles.formic,
      )
    }
  >
    {children}
  </div>
);

export default SelectBoxContainer;
