import React, { useState } from 'react';

import SelectBoxBG from './SelectBoxBG';
import SelectBoxBody from './SelectBoxBody';
import SelectBoxContainer from './SelectBoxContainer';

export interface SelectBoxProps {
    items: SelectBoxItems;
    name: string;
    title: string;
    handleChange?: (item: SelectBoxHandleChange) => void;
    form?: boolean; // Special style for form pages
    image?: boolean; // You can add image thumbnail to your select box
}

const SelectBox: React.FC<SelectBoxProps> = ({
  items, title, handleChange, name, form, image,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCloseSelectBox = (): void => setIsOpen(false);

  return (
    <SelectBoxContainer form={form} isOpen={isOpen}>
      {isOpen && <SelectBoxBG handleCloseSelectBox={handleCloseSelectBox} />}
      <SelectBoxBody
        name={name}
        title={title}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        items={items}
        handleChange={handleChange}
        image={image}
      />
    </SelectBoxContainer>
  );
};

export default SelectBox;
