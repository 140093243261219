import classNames from 'classnames';
import React from 'react';

import SettingsMenuItems from './SettingsMenuItems';
import SettingsSections from './SettingsSections';

import styles from './Settings.module.scss';

interface SettingsProps extends React.FC {
    MenuItems: typeof SettingsMenuItems;
    Sections: typeof SettingsSections;
}

const Settings: SettingsProps = ({ children }) => (
  <div className={classNames(styles.accountSettings, 'container')}>
    {children}
  </div>
);

Settings.MenuItems = SettingsMenuItems;
Settings.Sections = SettingsSections;

export default Settings;
